import styled from "@emotion/styled";
import { IonAlert, IonButton, IonIcon, IonLoading, IonSpinner } from "@ionic/react";
import { Stack } from "@mui/material";
import { ACCOUNTS_PAYROLL_PROOF_OF_EARNINGS_FULL_PATH } from "@src/appV2/Accounts/Payroll/ProofOfEarnings/paths";
import { environmentConfig } from "@src/appV2/environment";
import { useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { openInAppBrowser } from "@src/lib/deprecatedCode";
import { PayrollLineItem } from "@src/lib/interface/src";
import { checkmark, cogOutline, flash, logoUsd, mailOutline, warning } from "ionicons/icons";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { createLoginLink, payout } from "./api";
import { Bubble } from "./bubble";
import { LifetimeEarningsDropdown } from "./lifetimeEarningsDropdown";
import { Balance, ClipboardPaySchedule } from "./models";
import { PayrollItem } from "./payrollItem";
import { PayrollSettingsDropdown } from "./payrollSettingsDropdown";
import { MessageString } from "../../constants/strings";
import { USER_EVENTS } from "../../constants/userEvents";
import { formatCentsAsUsd, formatDollarsAsUsd } from "../../utils/currency";
import { generateDynamicLink, generateExpressSignInLinkToStripe } from "../onboardingStripe/api";

const HelperText = styled.p`
  margin-top: -4px;
  padding: -8px 0px 0px 0px;
  font-family: "SF Pro";
  font-size: 12px;
  color: #828282;
  text-align: center;
  font-style: italic;
`;
const PaymentSettingsTexts: Record<ClipboardPaySchedule, string> = {
  instantly: "You’re on auto-withdraw",
  weekly: "You’re on the automatic weekly payout schedule",
  daily: "You’re on the automatic daily payout schedule",
  manual: "You’re on a manual payout schedule",
};

export interface PayrollSummaryProps {
  balance: Balance;
  loading: boolean;
  setBalance: (balance: Balance) => void;
  handleBackClick: () => void;
  handleForwardClick: () => void;
  items: PayrollLineItem[];
  relativeWeek: number;
  lifetimeEarnings: number;
  currentPaySchedule: ClipboardPaySchedule | null;
  onPayScheduleChange: (s: ClipboardPaySchedule) => void;
}

export function PayrollSummary(props: PayrollSummaryProps) {
  const {
    balance,
    loading,
    setBalance,
    items,
    handleBackClick,
    handleForwardClick,
    relativeWeek,
    lifetimeEarnings,
    currentPaySchedule,
    onPayScheduleChange,
  } = props;
  const [showFeeAlert, setShowFeeAlert] = useState("");
  const [showCreateLoginLink, setShowCreateLoginLink] = useState(false);
  const [loadingPayout, setLoadingPayout] = useState(false);
  const [showStripeContactHelp, setShowStripeContactHelp] = useState(false);
  const location = useLocation();
  const { showSuccessToast, showErrorToast } = useToast();

  const [loadingCreateOnboardingLink, setLoadingCreateOnboardingLink] = useState(false);
  const worker = useDefinedWorker();

  const history = useHistory();
  const onClickCloseStripeContactHelp = useCallback(
    () => setShowStripeContactHelp(false),
    [setShowStripeContactHelp]
  );
  const onClickShowStripeContactHelp = useCallback(
    () => setShowStripeContactHelp(true),
    [setShowStripeContactHelp]
  );

  let hideDropDown: () => void;
  const storeHideDropDown = (fn: () => void) => (hideDropDown = fn);

  const getPaid = () => {
    logEvent(USER_EVENTS.INSTANT_PAYOUT_REQUESTED);
    setShowFeeAlert("manual");
  };

  const handleCreateOnboardingLink = async () => {
    try {
      setLoadingCreateOnboardingLink(true);
      const redirectUrl = await generateDynamicLink(
        `${environmentConfig.REACT_APP_WEB_APP_URL}${location.pathname}`
      );
      const link = await generateExpressSignInLinkToStripe(worker.userId as string, {
        returnUrl: redirectUrl,
      });
      openInAppBrowser(link, "_system");
      setLoadingCreateOnboardingLink(false);
    } catch (err) {
      setLoadingCreateOnboardingLink(false);
    }
  };

  const closeFeeModal = (confirm: boolean) => async () => {
    setShowFeeAlert("");

    const { autoWithdrawEnabled } = balance;
    if (!confirm) {
      logEvent(USER_EVENTS.INSTANT_PAYOUT_REJECTED);
      return;
    }
    logEvent(USER_EVENTS.INSTANT_PAYOUT_CONFIRMED);

    setLoadingPayout(true);
    try {
      await payout();
      setLoadingPayout(false);
      showSuccessToast(MessageString.payrollSummary.payoutSuccessful);
      hideDropDown();
      setBalance({
        ...balance,
        availableNow: 0,
        availableNowMinusFee: -balance.withdrawalFee,
        autoWithdrawEnabled,
      });
    } catch (error) {
      console.error(error);
      setLoadingPayout(false);
      const defaultMessage = MessageString.payrollSummary.payoutFailedForUnknownReason;
      const message =
        error.status === 400 ? error.response?.body?.message ?? defaultMessage : defaultMessage;
      showErrorToast(message);
    }
  };

  const handleCreateLoginLink = async () => {
    const redirectUrl = await generateDynamicLink(
      `${environmentConfig.REACT_APP_WEB_APP_URL}${location.pathname}`
    );
    const link = await createLoginLink(redirectUrl);
    openInAppBrowser(link, "_system");
  };

  const insuficientBalance = (): boolean => {
    return balance.availableNowMinusFee <= 0;
  };

  const getEarningsText = useMemo(() => {
    if (loading) {
      return "Loading...";
    }
    if (currentPaySchedule === "instantly") {
      return `You're on auto-withdraw`;
    }
    if (balance.availableNowMinusFee <= 0) {
      return `You don't have available funds to withdraw`;
    }

    return `${formatCentsAsUsd(balance.availableNow)} is available now`;
  }, [currentPaySchedule, loading, balance]);

  const getEarningsOnTheirWayText = useMemo(() => {
    if ((balance.availableSoon || 0) === 0) {
      return "No funds on the way";
    }
    if (balance.availableSoon < 0) {
      return `You have a negative balance of ${formatCentsAsUsd(balance.availableSoon)}`;
    }
    return `${formatCentsAsUsd(balance.availableSoon)} will be available soon`;
  }, [balance.availableSoon]);

  const gotoPoE = () => {
    history.push(ACCOUNTS_PAYROLL_PROOF_OF_EARNINGS_FULL_PATH);
  };

  return (
    <>
      <Stack width="100%" spacing={2}>
        {(loading || balance.hasDebitCard) && (
          <Bubble
            icone={flash}
            description="Earnings in Your Express Balance"
            loading={loading}
            value={<>{getEarningsText}</>}
            storeHideDropDown={storeHideDropDown}
            dropdown={
              <>
                {currentPaySchedule !== "instantly" && (
                  <div
                    style={{
                      backgroundColor: "#F3F3F3",
                      textAlign: "center",
                      padding: 21,
                    }}
                  >
                    <IonButton
                      style={{ minWidth: 154 }}
                      onClick={getPaid}
                      disabled={loading || insuficientBalance()}
                    >
                      GET PAID
                    </IonButton>
                  </div>
                )}
              </>
            }
          ></Bubble>
        )}
        {!loading && !balance.hasDebitCard && (
          <Bubble
            icone={flash}
            description="Earnings in Your Express Balance"
            loading={loading}
            value={<>Express balance is available for debit cards and most bank account types</>}
            dropdown={
              <div
                style={{
                  padding: 21,
                  textAlign: "center",
                  backgroundColor: "#f3f3f3",
                }}
              >
                <IonButton onClick={() => setShowCreateLoginLink(true)}>
                  CHANGE PAYOUT METHOD
                </IonButton>
              </div>
            }
          ></Bubble>
        )}
        <Bubble
          icone={checkmark}
          description="Earnings on Their Way"
          loading={loading}
          value={<>{getEarningsOnTheirWayText}</>}
        ></Bubble>
        {!loading && balance?.availableSoon > 0 && (
          <HelperText className="helperText">
            These are funds that are already in transit to your account and will be available soon.
          </HelperText>
        )}
        {!loading && balance?.availableSoon < 0 && (
          <HelperText className="helperText">
            When you incur a negative balance, an amount equal to the negative balance will be
            removed from your future earnings. This is caused by a shift adjustment after your
            instant pay.
          </HelperText>
        )}
        <Bubble
          icone={logoUsd}
          description="Lifetime Clipboard Earnings"
          loading={loading}
          value={
            <>
              You&apos;ve made{" "}
              <span style={{ color: "#1C69D1", fontWeight: "bold" }}>
                {formatDollarsAsUsd(lifetimeEarnings)}
              </span>{" "}
              so far!
            </>
          }
          valueFontSize={15}
          dropdown={
            <LifetimeEarningsDropdown
              handleBackClick={handleBackClick}
              handleForwardClick={handleForwardClick}
              items={items}
              relativeWeek={relativeWeek}
            ></LifetimeEarningsDropdown>
          }
        ></Bubble>
        <Bubble
          icone={cogOutline}
          description="Pay Settings"
          loading={loading}
          value={<span>{PaymentSettingsTexts[currentPaySchedule!]}</span>}
          storeHideDropDown={storeHideDropDown}
          dropdown={
            <PayrollSettingsDropdown
              balance={balance}
              currentPaySchedule={currentPaySchedule!}
              onPayScheduleChange={onPayScheduleChange}
            />
          }
        ></Bubble>
        <PayrollItem icone={mailOutline} name="Proof of Earnings" onClick={gotoPoE} />
        <IonButton
          style={{ minWidth: 154 }}
          onClick={handleCreateOnboardingLink}
          disabled={loading || loadingCreateOnboardingLink}
        >
          {loadingCreateOnboardingLink ? (
            <IonSpinner color="light" name="crescent" />
          ) : (
            "Take me to my Stripe account"
          )}
        </IonButton>
        <IonButton
          color="light"
          shape="round"
          className="contact-stripe-button"
          onClick={onClickShowStripeContactHelp}
        >
          <IonIcon icon={warning} />
          Can&apos;t login to Stripe?
        </IonButton>
      </Stack>
      {/* Misc */}

      {!loading && (
        <>
          <IonAlert
            isOpen={showFeeAlert !== ""}
            onDidDismiss={closeFeeModal(false)}
            header={"Send Funds?"}
            message={`You'll receive ${formatCentsAsUsd(
              balance.availableNowMinusFee
            )} after we remove ${formatCentsAsUsd(balance.withdrawalFee)} to cover processing cost`}
            backdropDismiss={true}
            buttons={[
              {
                text: "Never Mind",
                role: "cancel",
                handler: closeFeeModal(false),
              },
              {
                text: "Get Paid Now",
                handler: closeFeeModal(true),
              },
            ]}
          />

          <IonAlert
            isOpen={showCreateLoginLink}
            onDidDismiss={() => setShowCreateLoginLink(false)}
            header={"Change Payout Method"}
            message={`We'll take you to your Stripe account where you can update your payout information`}
            backdropDismiss={true}
            buttons={[
              {
                text: "Never Mind",
                role: "cancel",
                handler: () => setShowCreateLoginLink(false),
              },
              {
                text: "Change It",
                handler: handleCreateLoginLink,
              },
            ]}
          />

          <IonLoading
            isOpen={loadingPayout}
            onDidDismiss={() => setLoadingPayout(false)}
            message={"Please wait..."}
          />
        </>
      )}
      <IonAlert
        isOpen={showStripeContactHelp}
        onDidDismiss={onClickCloseStripeContactHelp}
        header={"Do you want to contact Stripe?"}
        message={`If you can’t login to your Stripe account, you need to contact them directly to solve your issue. Don’t forget to mention your account is of the express type.`}
        backdropDismiss
        mode="ios"
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Contact",
            handler: () => {
              logEvent(USER_EVENTS.CONTACT_STRIPE_CLICKED);
              window.location.href = "https://support.stripe.com/contact?skip_login=true";
            },
          },
        ]}
      />
    </>
  );
}
